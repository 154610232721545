export const SearchIcon = () => (
	<svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M18.2959 20.0012L11.4131 13.1172C8.35124 15.294 4.13216 14.7615 1.70715 11.8923C-0.717861 9.02307 -0.539799 4.77426 2.11678 2.11799C4.77266 -0.539451 9.02188 -0.718284 11.8917 1.7066C14.7614 4.13149 15.2942 8.35097 13.1172 11.4131L20 18.2971L18.2971 20L18.2959 20.0012ZM7.22558 2.40942C4.9418 2.40891 2.97149 4.01202 2.50756 6.24818C2.04363 8.48435 3.21358 10.739 5.30909 11.647C7.4046 12.555 9.84971 11.8669 11.1641 9.99924C12.4784 8.13157 12.3007 5.5977 10.7386 3.93172L11.4673 4.65432L10.6459 3.83537L10.6315 3.82092C9.73035 2.91426 8.50386 2.40597 7.22558 2.40942Z" />
	</svg>
);

export const Loader = () => (
	<svg x="0px" y="0px" width="40px" height="40px" viewBox="0 0 50 50">
		<path d="M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z">
			<animateTransform
				attributeType="xml"
				attributeName="transform"
				type="rotate"
				from="0 25 25"
				to="360 25 25"
				dur="0.6s"
				repeatCount="indefinite"
			/>
		</path>
	</svg>
);

export const Close = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
		<path fill="none" d="M0 0h24v24H0z" />
		<path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-11.414L9.172 7.757 7.757 9.172 10.586 12l-2.829 2.828 1.415 1.415L12 13.414l2.828 2.829 1.415-1.415L13.414 12l2.829-2.828-1.415-1.415L12 10.586z" />
	</svg>
);

export const Heart = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
		<path fill="none" d="M0 0H24V24H0z" />
		<path d="M20.243 4.757c2.262 2.268 2.34 5.88.236 8.236l-8.48 8.492-8.478-8.492c-2.104-2.356-2.025-5.974.236-8.236C5.515 3 8.093 2.56 10.261 3.44L6.343 7.358l1.414 1.415L12 4.53l-.013-.014.014.013c2.349-2.109 5.979-2.039 8.242.228z" />
	</svg>
);
